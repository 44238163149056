export const DISPLAY_MESSAGES = {
  FAILED: 'Tst_Msg_Failed',
  DASHBOARD_WIDGETS_ERROR: 'Tst_Msg_Dashboard_Widgets_Error',
  CALENDAR_EVENTS_EMPTY: 'Tst_Msg_Calendar_Events_Empty',
  NEWS_EMPTY: 'Tst_Msg_News_Empty',
  LOCATION_WIDGET_EMPTY: 'Tst_Msg_Location_Widget_Empty',
  ENGAGEMENT_WIDGET_EMPTY: 'Tst_Msg_Engagement_Widget_Empty',
  FORGOT_PASSWORD_EMAIL_SENT: 'Tst_Msg_Forgot_Password_Email_Sent',
  PASSWORD_CHANGED: 'Tst_Msg_Password_Changed',
  VERIFY_EMAIL: 'Tst_Msg_Verify_Email',
  FAILED_SET_DEFAULT_ADDRESS: 'Tst_Msg_Failed_Set_Default_Address',
  PROFILE_UPDATED: 'Tst_Msg_Profile_Updated',
  WIDGET_SETTINGS_UPDATED: 'Tst_Msg_Widget_Settings_Updated',
  ADDRESS_UPDATED: 'Tst_Msg_Address_Updated',
  WIDGET_REORDER_UPDATE_SUCCESS: 'Tst_Msg_Widget_Reorder_Update_Success',
  LINK_ACCOUNT_SUCCESS: 'Tst_Msg_Link_Account_Success',
  UNLINK_ACCOUNT_SUCCESS: 'Tst_Msg_Unlink_Account_Success',
  EXTERNAL_LINK_ACCOUNT_ERROR: 'Tst_Msg_External_Link_Account_Error',
  SEND_VERIFY_MAIL: 'Tst_Msg_Send_Verify_Mail',
  ADDRESS_ERROR: 'Tst_Msg_Address_Error',
  GENERIC_ERROR: 'Tst_Msg_Generic_Error',
  LINK_ACCOUNT_ERROR: 'Tst_Msg_Link_Account_Error',
  UNLINK_ACCOUNT_ERROR: 'Tst_Msg_Unlink_Account_Error',
  POPUP_BLOCKED_MESSAGE: `Tst_Msg_Popup_Blocked_Message`,
  LANGUAGE_CHANGED_ERROR: 'Txt_Language_Changed_Error',
  UPDATE_LANGUAGE_PREFERENCE_ERROR: 'Txt_Account_Preferences_Update_Error',
  UPDATE_LANGUAGE_PREFERENCE_SUCCESS: 'Txt_Account_Preferences_Update_Success',
  FAILED_TO_DELETE_ACCOUNT: 'Txt_Msg_Account_Failed'
}

export const IDLETIMEOUT = 30 * 60

export const CONFIRM_DIALOG_MESSAGES = {
  CONFIRM: 'Mdl_Hdr_Please_confirm',
  UNLINK_ACCOUNT: 'Mdl_Msg_Unlink_account_Confirmation',
  DELETE_PROPERTY: 'Mdl_Msg_Delete_Property_Confirmation'
}

export const ADDRESS_MODE_ENUMS = {
  ADD: 'add',
  EDIT: 'edit',
  SIGNUP: 'signup'
}

export const RecreationBookingEnums = {
  SINGLEBOOKING: 'SingleBooking',
  COURCES: 'Courses',
  FACILITYBOOKING: 'FacilityBooking'
}

export const NOTIFICATIONS_DROPDOWN_LIST = [
  { text: 'Slt_Widget_Settings_None', value: 0, isDisabled: false, index: 1 },
  { text: 'Slt_Widget_Settings_App_only', value: 1, isDisabled: false, index: 2 },
  {
    text: 'Slt_Widget_Settings_App_EmailImmediate',
    value: 2,
    isDisabled: false,
    index: 3
  },
  {
    text: 'Slt_Widget_Settings_App_EmailSummary',
    value: 3,
    isDisabled: false,
    index: 4
  }
]

export const URLS = {
  DesignedBy: 'https://ghddigitalpss.com/',
  Support: 'https://ghddigitalpss.com/support/'
}


export const WIDGET_CATEGORIES = {
  1: {
    type: 'Content',
    componentName: null,
    widgetCategoryId: 1
  },
  2: {
    type: 'Calendar',
    componentName: 'CalendarWidgetContent',
    widgetCategoryId: 2
  },
  3: {
    type: 'News',
    componentName: 'NewsWidgetContent',
    widgetCategoryId: 3
  },
  4: {
    type: 'Location',
    componentName: 'LocationWidgetContent',
    widgetCategoryId: 4
  },
  5: {
    type: 'Integration',
    componentName: 'IntegrationWidgetContent',
    widgetCategoryId: 5
  },
  6: {
    type: 'Form Builder',
    componentName: 'FormBuilderWidgetContent',
    widgetCategoryId: 6
  },
  7: {
    type: 'Engagement',
    componentName: 'EngagementWidgetContent',
    widgetCategoryId: 7
  },
  8: {
    type: 'MyWaste',
    componentName: 'MyWasteWidgetContent',
    widgetCategoryId: 8
  },
  9: {
    type: 'Recreation',
    componentName: 'RecreationWidgetContent',
    widgetCategoryId: 9
  }
}

export const AZURE_AD_B2C_ERROR_CODES = {
  CANCELED: 'AADB2C90091'
}

export const NO_UNIT_NUMBER = 'no unit number'

export const SUBDOMAIN_NAME = window.location.hostname.match(/\w*\.\w*$/gi) ? window.location.hostname.match(/\w*\.\w*$/gi)[0] : null
