<template>
  <component
    :is="getComponentName"
    :widgetId="widgetDetails.widgetId"
    :isExpandedView="isExpandedView"
    :data="widgetDetails.data"
    :expandedVwTxt="widgetDetails.expandedVwTxt"
    :mode="mode"
    @showExpandedView="$emit(`showExpandedView`)"
  ></component>
</template>
<script>
import { WIDGET_CATEGORIES } from '../../../utilities/constants'
export default {
  name: 'WidgetCategories',
  props: {
    widgetDetails: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: '',
    },
  },
  provide() {
    return {
      mode: this.mode,
    }
  },
  data() {
    return {
      widgetCategories: WIDGET_CATEGORIES,
    }
  },
  computed: {
    getComponentName() {
      return this.widgetCategories[this.widgetDetails.widgetCategoryId].componentName
    },
  },
  components: {
    CalendarWidgetContent: () => import('../calendar-widget/CalendarWidgetContent.vue'),
    NewsWidgetContent: () => import('../news-widget/NewsWidgetContent.vue'),
    LocationWidgetContent: () => import('../location-widget/LocationWidgetContent.vue'),
    IntegrationWidgetContent: () => import('../integration-widget/IntegrationWidgetContent.vue'),
    FormBuilderWidgetContent: () => import('../form-builder-widget/FormBuilderWidgetContent.vue'),
    EngagementWidgetContent: () => import('../engagement-widget/EngagementWidgetContent.vue'),
    MyWasteWidgetContent: () => import('../mywaste-widget/MyWasteWidgetContent.vue'),
    RecreationWidgetContent: () => import('../recreation-widget/RecreationWidgetContent.vue'),
  },
}
</script>
